/** Internal helper imports here ... */

/**
 * Module description...
 *
 * @returns {{init: init}}
 */
const BackToTop = (() => {
  const DOM = {};

  const cacheDOM = () => {
    DOM.icon = document.getElementById("backToTop");
  }

  const backToTop = () => {
    window.onscroll = function(ev) {
      if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 2000 )) {
        DOM.icon.classList.add('active');
      } else {
        DOM.icon.classList.remove('active');
      }
    };
  }

  const init = () => {
    cacheDOM();
    backToTop();
  };

  return {
    init,
  };
})();

export default BackToTop;
