import Breakpoint from '../helpers/breakpoint';

/**
 * Module description...
 *
 * @returns {{init: init}}
 */
const MainNav = (() => {
  const DOM = {};
  const transitionBreakpoint = "md";

  const cacheDOM = () => {
    DOM.hamburger = document.getElementsByClassName("toggleNav");
    DOM.toggleNav = document.getElementsByClassName("nav");
    DOM.menuItems = document.querySelectorAll(".menu-item > a");
  };

  const toggleNav = () => {
    const menu = DOM.toggleNav[0];
    const hamburger = DOM.hamburger[0];

    if (!hamburger.classList.contains("open")) {
      hamburger.classList.add("open");
      menu.classList.add("active");
    } else {
      hamburger.classList.remove("open");
      menu.classList.remove("active");
    }
  };


  const eventListeners = () => {
    let addMobileNavEventListeners = () => {
      DOM.hamburger[0].addEventListener("click", toggleNav);
      Array.from(DOM.menuItems).forEach((HTMLNode) => {
        HTMLNode.addEventListener("click", toggleNav);
      });
    }

    if (Breakpoint.isBreakpointDown(transitionBreakpoint)) {
      addMobileNavEventListeners();
    }

    window.addEventListener('resize', function () {
      if (Breakpoint.isBreakpointDown(transitionBreakpoint)) {
        addMobileNavEventListeners();
      } else {
        DOM.hamburger[0].removeEventListener("click", toggleNav);
        Array.from(DOM.menuItems).forEach((HTMLNode) => {
          HTMLNode.removeEventListener("click", toggleNav);
        });
      }
    }, false);
  };

  const init = () => {
    cacheDOM();
    eventListeners();
  };

  return {
    init,
  };
})();

export default MainNav;
