const VideoPlayer = (() => {
  const DOM = {};

  const cacheDOM = () => {
    DOM.videoEL = document.getElementsByClassName("video__container");
  };

  const getYouTubeThumb = (id) => {
    const thumb = "<img src='https://i.ytimg.com/vi/ID/hqdefault.jpg'>";
    const play = "<div class='play'></div>";
    return thumb.replace("ID", id) + play;
  };
  const getVimeoThumb = (id, cb) => {
    const request = new XMLHttpRequest();
    const url = "https://vimeo.com/api/v2/video/" + id + ".json";
    request.open("GET", url, true);
    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        const data = JSON.parse(request.responseText);
        const thumbNail = data[0].thumbnail_large;
        cb(data);
      } else {
        console.log("Error");
      }
    };
    request.send();
  };
  const showThumb = (data) => {
    const play = document.createElement("i");
    play.classList.add("play");
    const img = new Image();
    img.src = data[0].thumbnail_large;
    const div = document.getElementById(data[0].id);
    div.appendChild(img);
    div.appendChild(play);
    div.addEventListener("click", setIFrame);
  };
  const setIFrame = (el) => {
    const iframe = document.createElement("iframe");
    let embed;
    if (el.currentTarget.className === "vimeo-player") {
      embed = "https://player.vimeo.com/video/ID?autoplay=1";
      iframe.setAttribute("height", el.currentTarget.children[0].clientHeight);
      iframe.setAttribute("width", el.currentTarget.children[0].clientWidth);
    } else {
      embed = "https://www.youtube.com/embed/ID?autoplay=1";
    }
    iframe.setAttribute(
      "src",
      embed.replace("ID", el.currentTarget.dataset.id)
    );
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "1");
    el.currentTarget.parentNode.replaceChild(iframe, el.currentTarget);
  };
  const videoIframe = (el) => {
    const player = el.className;
    switch (player) {
      case "vimeo-player":
        getVimeoThumb(el.dataset.id, showThumb);
        break;
      default:
        const videContainer = document.createElement("div");
        videContainer.setAttribute("data-id", el.dataset.id);
        videContainer.innerHTML = getYouTubeThumb(el.dataset.id);
        videContainer.onclick = setIFrame;
        el.appendChild(videContainer);
        break;
    }
  };
  const init = () => {
    cacheDOM();
    if (DOM.videoEL.length > 0) {
      Array.from(DOM.videoEL).forEach( (el) => {
        const videoWrapper = el.firstElementChild;
        videoIframe(videoWrapper);
      });
    }
  };
  return {
    init,
  };
})();
export default VideoPlayer;
